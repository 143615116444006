/* theme.css */

:root {
    --light-background: #ffffff;
    --light-text: #333333;
    --light-subtext: #666666;
    --light-border: #dddddd;

    --dark-background: #333333;
    --dark-text: #ffffff;
    --dark-subtext: #bbbbbb;
    --dark-border: #555555;
}

body.light-mode {
    background-color: var(--light-background);
    color: var(--light-text);
}

body.dark-mode {
    background-color: var(--dark-background);
    color: var(--dark-text);
}

button.light-mode {
    background-color: var(--light-background);
    color: var(--light-text);
}

button.dark-mode {
    background-color: var(--dark-background);
    color: var(--dark-text);
}