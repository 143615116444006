.post-summary {
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 60%;
    /* Adjust this to control the width of the post summaries */
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.post-date {
    font-size: 14px;
}

.post-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.post-content {
    font-size: 18px;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 800px;
    /* Set a max width for your content, to avoid it spreading too wide on large screens */
}



