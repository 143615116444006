/* Header.module.css */

.header {
    display: flex;
    padding: 20px;
    justify-content:space-between;
}

.title {
    margin: 0;
}

.button {
    padding: 2px 10px;
    margin: 10px;
    border: none;
}

button:hover {
    cursor: pointer;
}



